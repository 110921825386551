import { http } from './http';
import store from '../store';
import { logout } from '../store/slices/user/user.thunks';

const { dispatch } = store;

const setup = () => {
  http.interceptors.request.use(config => {
    const token = window.localStorage.getItem('token');

    if (config.url !== '/token') {
      if (token) {
        config.headers['X-AUTH-TOKEN'] = token;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  });

  http.interceptors.response.use(response => {
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      dispatch(logout());
    }

    return Promise.reject(error);
  });
};

export const interceptorsSetup = {
  setup
};
