import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { interceptorsSetup } from './helpers/interceptors';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

interceptorsSetup.setup();

const instance = createInstance({
  urlBase: `${process.env.REACT_APP_MATOMO_PREFIX_URL}${process.env.REACT_APP_MATOMO_URL}`,
  siteId: process.env.REACT_APP_MATOMO_SITEID,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
