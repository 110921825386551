import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query'


const Title = ({ icon, label, subtitle, marginBottom, marginTop }) => {
  return (
    <Wrapper
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <TitleIcon>
        <Dash />
        <FontAwesomeIcon icon={['fal', icon]} />
        <Dash />
      </TitleIcon>

      <TitleLabel dangerouslySetInnerHTML={{__html: label}} />

      {subtitle && (
        <SubtitleLabel>
          {subtitle}
        </SubtitleLabel>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.fontTitle};
  margin-bottom: ${props => props.marginBottom ? '2rem' : '0.5rem'};
  margin-top: ${props => props.marginTop ? '1rem' : '0'};
`;

const TitleIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;

  > * {
    margin: 0 0.5rem;
  }
`;

const Dash = styled.div`
  height: 2px;
  width: 50%;
  max-width: 16rem;
  background: ${props => props.theme.colors.fontTitle};
`;

const TitleLabel = styled.span`
  margin-top: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
`;

const SubtitleLabel = styled.span`
  margin-top: 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0;
  color: ${props => props.theme.colors.fontGray};
`;

Title.defaultProps = {
  marginBottom: true,
  marginTop: true,
  label: '',
};

Title.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  subtitle: PropTypes.string,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
};

export default Title;
