import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFR from './i18n/fr.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          default:
            return value;
        }
      }
    },
  });

export default i18n;
