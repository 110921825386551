export const bookingDataSelector = state => {
  return state.bookingSlice.bookingData;
};

export const firstIntentionSelector = state => {
  return state.bookingSlice.firstIntention;
};

export const bookingOptionsSelector = state => {
  return state.bookingSlice.bookingOptions;
};

export const dataBookingConfirm = state => {
  return state.bookingSlice.bookingConfirm;
};

export const dataAlreadyBooking = state => {
  return state.bookingSlice.alreadyBooking;
};

export const dataIsCancelable = state => {
  return state.bookingSlice.isCancelable;
};

export const dataNotCancelableReasonKey = state => {
  return state.bookingSlice.notCancelableReasonKey;
};

export const dataTimeout = state => {
  return state.bookingSlice.timeout;
};

export const formPaymentRemoteMPI = state => {
  return state.bookingSlice.formPaymentRemoteMPI;
};

export const dataIsUpdateMode = state => {
  return state.bookingSlice.isUpdateMode;
};

export const dataIsUpdateChildrenMode = state => {
  return state.bookingSlice.isUpdateChildrenMode;
};

export const dataIsSpecialEvent = state => {
  return state.bookingSlice.isSpecialEvent;
};

export const dataEventId = state => {
  return state.bookingSlice.eventId;
};

export const dataAmount = state => {
  return state.bookingSlice.amount;
}

export const dataIsUpdateBirthday = state => {
  return state.bookingSlice.isUpdateBirthday;
};
