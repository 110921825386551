import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAvailable: false,
  closing: {
    isClosed: false,
    reason: '',
  },
  availabilities: [],
  availableTimesAndRoom: {
    roomspertime: {},
    rooms: [],
    isGardenOpen: false,
    timelabel: {},
    prefFeedBack: null,
    halfTime: null,
    feedBack: null,
  },
  isEligibleForVipRoom: false,
  specialEvents: [],
};

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setIsAvailable (state, action) {
      return {
        ...state,
        isAvailable: action.payload,
      };
    },
    setClosing (state, action) {
      return {
        ...state,
        closing: {
          ...state.closing,
          ...action.payload,
        },
      };
    },
    setIsEligibleForVipRoom(state, action) {
      return {
        ...state,
        isEligibleForVipRoom: action.payload,
      };
    },
    setAvailabilities(state, action) {
      return {
        ...state,
        availabilities: action.payload,
      };
    },
    setAvailableTimesAndRoom(state, action) {
      return {
        ...state,
        availableTimesAndRoom: {
          ...state.availableTimesAndRoom,
          ...action.payload,
        },
      };
    },
    setSpecialEvents (state, action) {
      return {
        ...state,
        specialEvents: action.payload,
      };
    },
    resetSpecialEvents (state) {
      return {
        ...state,
        specialEvents: initialState.specialEvents,
      };
    },
  },
});

export const {
  setIsAvailable,
  setClosing,
  setIsEligibleForVipRoom,
  setAvailabilities,
  setAvailableTimesAndRoom,
  setSpecialEvents,
  resetSpecialEvents,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
