import { http } from '../../../helpers/http';
import axios from 'axios';
import i18n from '../../../i18n';
import {
  resetState as resetUserState,
  setAuthenticatedUser,
  setIsValidAccount,
  setUserInformationStep1,
} from './user.slice';
import { setUserIsLoading, setUserIsLoadingForgotPwd } from '../loading/loading.slice';
import { toast } from 'react-toastify';
import { setContactStep } from '../tunnel/tunnel.slice';

const CancelToken = axios.CancelToken;
let source = null;

export const getIsValidAccount = email => async dispatch => {
  if (source) {
    source.cancel();
  }
  dispatch(setUserIsLoading(true));
  try {
    source = CancelToken.source();
    const isValidAccountData = await http.get(
      `/user/is-valid-email?email=${email}`,
      {
        cancelToken: source.token,
      }
    );
    const isValidAccount = isValidAccountData.data.isValidEmail;

    dispatch(setIsValidAccount(isValidAccount));
    dispatch(setUserIsLoading(false));
  } catch (err) {
    if (!axios.isCancel(err)) {
      dispatch(setIsValidAccount(false));
      dispatch(setUserIsLoading(false));
      toast.error(i18n.t(err.response.data.error));
    }
  }
};

export const postUserToken = userInformation => async dispatch => {
  dispatch(setUserIsLoading(true));
  try {
    const userToken = await http.post(
      `/auth-tokens`,
      userInformation,
    );

    const token = userToken.data.token;
    window.localStorage.setItem('token', token);

    dispatch(setAuthenticatedUser(true));
    dispatch(setUserInformationStep1(userToken.data.client));
    dispatch(setContactStep(true));
  } catch (err) {
    dispatch(setAuthenticatedUser(false));
    toast.error(i18n.t(err.response.data.message));
  } finally {
    dispatch(setUserIsLoading(false));
  }
};

export const postUserTokenByMail = authInformation => async dispatch => {
  dispatch(setUserIsLoading(true));
  try {
    const userToken = await http.post(
      `/auth-tokens-mail`,
      authInformation,
    );

    const token = userToken.data.token;
    window.localStorage.setItem('token', token);

    dispatch(setAuthenticatedUser(true));
    dispatch(setUserInformationStep1(userToken.data.client));
    dispatch(setContactStep(true));
  } catch (err) {
    dispatch(setAuthenticatedUser(false));
    toast.error(i18n.t(err.response.data.message));
  } finally {
    dispatch(setUserIsLoading(false));
  }
};

export const postNewAccount = (email, sendAdditionalInformationAgreement) => async dispatch => {
  dispatch(setUserIsLoadingForgotPwd(true));

  try {
    await http.post(
      `/user/send-mail-new-client`, {
        email,
        sendAdditionalInformationAgreement,
      },
    );

    toast.success(i18n.t('success_booking_link_send'));
  } catch (err) {
    toast.error(i18n.t(err.response.data.error));
  } finally {
    dispatch(setUserIsLoadingForgotPwd(false));
  }
};

export const sendResetPassword = (email) => async dispatch => {
  dispatch(setUserIsLoadingForgotPwd(true));

  try {
    await http.post(
      `/user/send-reset-password`, {
        email,
      },
    );

    toast.success(i18n.t('success_booking_link_send'));
  } catch (err) {
    toast.error(i18n.t('error_booking_link_send'));
  } finally {
    dispatch(setUserIsLoadingForgotPwd(false));
  }
};

export const postUserTokenByUpdateToken = authInformation => async dispatch => {
  dispatch(setUserIsLoading(true));
  try {
    const userToken = await http.post('/auth-tokens-update', authInformation);

    const token = userToken.data.token;
    window.localStorage.setItem('token', token);

    dispatch(setAuthenticatedUser(true));
  } catch (err) {
    dispatch(setAuthenticatedUser(false));
    toast.error(i18n.t('invalid_identifiers'));
  } finally {
    dispatch(setUserIsLoading(false));
  }
};

export const logout = () => async dispatch => {
  window.localStorage.removeItem('token');
  dispatch(setAuthenticatedUser(false));
};

export const resetState = () => dispatch => {
  dispatch(resetUserState());
};

export const postCreateClientAccount = userInformation => async dispatch => {
  dispatch(setUserIsLoading(true));
  try {
    await http.post(`user/create-client-account`, userInformation);
  } catch (err) {
    toast.error(i18n.t('error_create_client_account'));
  } finally {
    dispatch(setUserIsLoading(false));
  }
};
export const postUpdateClient = (client) => {
  try {
    http.post('user/update-client', { client });
    toast.success(i18n.t('user_step1.personal_information_updated'))
  }
  catch (err) {
    toast.error(i18n.t('error_create_client_account'));
  }
};
