import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInformationStep1: {
    id: null,
    gender: '',
    lastname: '',
    firstname: '',
    phone: '',
    french: '',
    address: '',
    zipcode: '',
    city: '',
    country: '',
    email: '',
  },
  userInformationStep3: {
    stayNarbonne: '',
    roundTrip: '',
    stay: '',
  },
  authenticatedUser: false,
  isValidAccount: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInformationStep1(state, action) {
      return {
        ...state,
        userInformationStep1: {
          ...state.userInformationStep1,
          ...action.payload,
        },
      };
    },
    setUserInformationStep3(state, action) {
      return {
        ...state,
        userInformationStep3: {
          ...state.userInformationStep3,
          ...action.payload,
        },
      };
    },
    setAuthenticatedUser(state, action) {
      return {
        ...state,
        authenticatedUser: action.payload,
      };
    },
    setIsValidAccount(state, action) {
      return {
        ...state,
        isValidAccount: action.payload,
      };
    },
    resetState(state, action) {
      return initialState;
    },
    resetUserInformationStep3(state, action) {
      return {
        ...state,
        userInformationStep3: initialState.userInformationStep3,
      };
    },
  },
});

export const {
  setUserInformationStep1,
  setUserInformationStep3,
  setAuthenticatedUser,
  setIsValidAccount,
  resetState,
  resetUserInformationStep3,
} = userSlice.actions;

export default userSlice.reducer;
