import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    cities: [],
    countries: {},
  },
  reducers: {
    setCities(state, action) {
      state.cities = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
  },
});

export const {
  setCities,
  setCountries,
} = dataSlice.actions;

export default dataSlice.reducer;
