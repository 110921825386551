import { createSlice } from '@reduxjs/toolkit';

const tunnelSlice = createSlice({
  name: 'tunnel',
  initialState: {
    contact: false,
    dateAndGuest: false,
    timeAndRoom: false,
    userInformation: false,
    bookingOption: false,
    bookingComplete: false,
    isSubmit: false,
  },
  reducers: {
    setContactStep(state, action) {
      state.contact = action.payload;
    },
    setDateAndGuestStep(state, action) {
      state.dateAndGuest = action.payload;
    },
    setTimeAndRoomStep(state, action) {
      state.timeAndRoom = action.payload;
    },
    setUserInformationStep(state, action) {
      state.userInformation = action.payload;
    },
    setBookingOptionStep(state, action) {
      state.bookingOption = action.payload;
    },
    setBookingCompleteStep(state, action) {
      state.bookingComplete = action.payload;
    },
    setIsSubmit(state, action) {
      state.isSubmit = action.payload;
    },
  },
});

export const {
  setContactStep,
  setDateAndGuestStep,
  setTimeAndRoomStep,
  setUserInformationStep,
  setBookingOptionStep,
  setBookingCompleteStep,
  setIsSubmit,
} = tunnelSlice.actions;

export default tunnelSlice.reducer;
