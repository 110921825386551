import React, { Suspense } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import GuardedRouteContainer from '../Containers/Router/GuardedRouteContainer';
import Layout from './Layout';

import FourZeroFour from './FourZeroFour';
import LoadingState from './LoadingState';

// Lazily import app screens
const Contact = React.lazy(() => import('../Containers/Screens/ContactContainer'));
const DateAndGuests = React.lazy(() => import('../Containers/Screens/DateAndGuestsContainer'));
const TimeAndRoom = React.lazy(() => import('../Containers/Screens/TimeAndRoomContainer'));
const UserInformation = React.lazy(() => import('../Containers/Screens/UserInformationContainer'));
const BookingOptions = React.lazy(() => import('../Containers/Screens/BookingOptionsContainer'));
const AuthByMail = React.lazy(() => import('../Containers/Screens/AuthByMailContainer'));
const BookingComplete = React.lazy(() => import('../Containers/Screens/BookingCompleteContainer'));
const TermsOfService = React.lazy(() => import('./TermsOfService'));
const UpdateBooking = React.lazy(() => import('../Containers/Screens/UpdateBookingContainer'));
const CancelBooking = React.lazy(() => import('../Containers/Screens/CancelBookingContainer'));
const PaymentWaitAutorisation = React.lazy(() => import('../Containers/Screens/PaymentWaitAutorisationContainer'));
const PaymentError = React.lazy(() => import('../Containers/Screens/PaymentErrorContainer'));
const BookingConfirmation = React.lazy(() => import('../Containers/Screens/BookingConfirmationContainer'));


const AppRouter = () => {
  return (
    <Suspense fallback={<LoadingState />}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Redirect to="/contact" />
            </Route>
            <Route exact path="/contact" component={Contact} />
            <GuardedRouteContainer exact path="/date-and-guests">
              <DateAndGuests />
            </GuardedRouteContainer>
            <GuardedRouteContainer exact path="/time-and-room">
              <TimeAndRoom />
            </GuardedRouteContainer>
            <GuardedRouteContainer exact path="/user-information">
              <UserInformation />
            </GuardedRouteContainer>
            <GuardedRouteContainer exact path="/booking-options">
              <BookingOptions />
            </GuardedRouteContainer>
            <GuardedRouteContainer exact path="/booking-complete">
              <BookingComplete />
            </GuardedRouteContainer>
            <Route exact path="/terms-of-service">
              <TermsOfService />
            </Route>
            <Route exact path="/authenticate-by-mail">
              <AuthByMail />
            </Route>
            <Route exact path="/booking/update">
              <UpdateBooking />
            </Route>
            <Route exact path="/booking/cancel">
              <CancelBooking />
            </Route>
            <GuardedRouteContainer exact path="/booking/3d-secure">
              <PaymentWaitAutorisation />
            </GuardedRouteContainer>
            <GuardedRouteContainer exact path="/booking/error">
              <PaymentError />
            </GuardedRouteContainer>
            <Route exact path="/booking/confirm">
              <BookingConfirmation />
            </Route>
            <Route component={FourZeroFour} />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
