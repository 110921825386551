import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const GuardedRoute = ({
  isUpdateChildrenMode,
  logout,
  children,
  bookingConfirm,
  isUpdateBirthday,
  ...rest
}) => {

  if (
    (isUpdateChildrenMode || isUpdateBirthday) &&
    rest.path !== '/booking-options' &&
    rest.path !== '/booking-complete'
  ) {
    logout();
    return <Redirect to='/contact' />;
  }

  if (
    bookingConfirm.paybox &&
    rest.path !== '/booking-complete' &&
    rest.path !== '/booking/3d-secure' &&
    rest.path !== '/booking/error' &&
    rest.path !== '/booking-confirm'
  ) {
    return <Redirect to='/booking-complete' />;
  }

  return (
    <Route
      {...rest}
      render={() => (
        window.localStorage.getItem('token')
          ? children
          : <Redirect to='/contact' />
      )} />
  );
}

export default GuardedRoute;
