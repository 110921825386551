import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavDesktop = ({
  navItems,
  bookingConfirm,
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleNavigate = (currentNavItem) => {
    if (currentNavItem.isClickable && !bookingConfirm.paybox) {
      history.push(currentNavItem.targetPath);
    }
  };

  return (
    <NavWrapper>
      {navItems.map((currentNavItem) => (
        <NavItem
          key={currentNavItem.id}
          navItems={navItems}
          isActive={location.pathname === currentNavItem.targetPath}
          isFinalStep={currentNavItem.isFinalStep}
          onClick={() => handleNavigate(currentNavItem)}
          disabled={!currentNavItem.isClickable || bookingConfirm.paybox}
        >
          <NavItemIcon>
            <FontAwesomeIcon icon={['fal', currentNavItem.icon]} />
          </NavItemIcon>

          <NavItemLabel>
            {currentNavItem.label}
          </NavItemLabel>
        </NavItem>
      ))}
    </NavWrapper>
  );
};

const NavWrapper = styled.div`
  width: 100%;
  height: 5rem;
  border-bottom: 3px solid ${props => props.theme.colors.brand};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% / ${props => props.navItems.length});
  background: ${props => {
    if (props.isFinalStep && props.isActive) return props.theme.colors.brand
    if (props.isFinalStep) return props.theme.colors.pannelBackgroundDarker
    return props.isActive ? props.theme.colors.brand : null
  }};
  color: ${props => props.isActive ? props.theme.colors.neutral100 : props.theme.colors.fontTitle};
  cursor: ${props => props.disabled ? 'cursor' : 'pointer'};
`;

const NavItemIcon = styled.div`
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
`;

const NavItemLabel = styled.span`
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
`;

NavDesktop.propTypes = {
  navItems: PropTypes.array.isRequired,
  bookingConfirm: PropTypes.object.isRequired,
};

export default NavDesktop;
