import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Components/UI/Button';
import styled from 'styled-components';

const FourZeroFour = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Title>404</Title>
      <SubTitle>page non trouvée</SubTitle>

      <Button onClick={() => history.push('/')}>Retour</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.fontTitle};
  margin: 0;
  margin-top: 2rem;
  font-size: 4rem;
`;

const SubTitle = styled.h2`
  color: ${props => props.theme.colors.fontTitle};
  margin: 0;
  margin-bottom: 2rem;
`;

export default FourZeroFour;
