import React from 'react';
import styled from 'styled-components';
import Nav from '../../Containers/Screens/Layout/NavContainer';
import lgbLogo from '../../assets/images/logo.png';
import { useMobileResolutionCheck } from '../../Hooks/resolution';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  const isMobile = useMobileResolutionCheck();

  return (
    <LayoutWrapper>
      {!isMobile && (
        <Header>
          <Logo src={lgbLogo} />
        </Header>
      )}
      <ScreenWrapper>
        <Nav />
        <ScreenContent>
          { children }
        </ScreenContent>
      </ScreenWrapper>
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        style={{ width: isMobile ? '100%': 'max-content' }}
        autoClose={7000}
      />
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${props => props.theme.colors.background};
`;

const Header = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  @media ${props => props.theme.breakpoints.desktop} {
    height: 8rem;
    justify-content: flex-start;
  }
`;

const Logo = styled.img`
  height: 50px;
  width: auto;

  @media ${props => props.theme.breakpoints.desktop} {
    height: 70px;
  }
`;

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  background: ${props => props.theme.colors.pannelBackground};
  border-radius: 0;
  flex: 1;

  @media ${props => props.theme.breakpoints.desktop} {
    flex: 0 1 auto;
  }
`;

const ScreenContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  > div:first-of-type {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 1rem 0.5rem;
  }
`;

export default Layout;
