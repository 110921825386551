import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_BASE_URL
})

http.defaults.headers.common['Accept'] = 'application/json';
http.defaults.headers.put['Content-Type'] = 'application/json';
http.defaults.headers.get['Content-Type'] = 'application/json';
http.defaults.headers.post['Content-Type'] = 'application/json';
http.defaults.headers.delete['Content-Type'] = 'application/json';

export { http };
