import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingData: {
    participants: '',
    service: '',
    selectedDate: '',
    time: '',
    room: '',
    eventId: ''
  },
  firstIntention: {
    participants: '',
    service: '',
    selectedDate: '',
  },
  bookingOptions: {
    birthday: '',
    ages: [],
    hasChildrenChair: '',
    childCount: '',
    hasPRM: '',
    handicappedPaxCount: '',
    newsletter: '',
    link: '',
    beenThere: '',
    withVoucher: '',
    hasChildRate: '',
    childRate: [''],
    childRateAge: [''],
    cgr: '',
    cgrProperAttire1: '',
    cgrProperAttire2: '',
    cgrProperAttire3: '',
    stroller: '',
  },
  bookingConfirm: {
    token: '',
    paybox: false,
    validate: false,
  },
  alreadyBooking: {
    success: true,
    feedback: '',
  },
  isCancelable: true,
  notCancelableReasonKey: null,
  timeout: new Date().getTime(),
  formPaymentRemoteMPI: {},
  isUpdateMode: false,
  isUpdateChildrenMode: false,
  isSpecialEvent: null,
  amount: parseInt(process.env.REACT_APP_IMPRINT_AMOUNT),
  isUpdateBirthday: false,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingData(state, action) {
      return {
        ...state,
        bookingData: {
          ...state.bookingData,
          ...action.payload
        },
        timeout: new Date().getTime(),
      }
    },
    setFirstIntention(state, action) {
      return {
        ...state,
        firstIntention: {
          ...state.firstIntention,
          ...action.payload
        },
        timeout: new Date().getTime(),
      };
    },
    setBookingOptions(state, action) {
      return {
        ...state,
        bookingOptions: {
          ...state.bookingOptions,
          ...action.payload
        },
        timeout: new Date().getTime(),
      };
    },
    setBookingConfirm(state, action) {
      return {
        ...state,
        bookingConfirm: {
          ...state.bookingConfirm,
          ...action.payload
        },
        timeout: new Date().getTime(),
      };
    },
    resetState(state, action) {
      return initialState;
    },
    setAlreadyBooking(state, action) {
      return {
        ...state,
        alreadyBooking: {
          ...state.alreadyBooking,
          ...action.payload
        },
        timeout: new Date().getTime(),
      };
    },
    setIsCancelable(state, action) {
      return {
        ...state,
        isCancelable: action.payload,
        timeout: new Date().getTime(),
      };
    },
    setNotCancelableReasonKey(state, action) {
      return {
        ...state,
        notCancelableReasonKey: action.payload,
        timeout: new Date().getTime(),
      };
    },
    setFormPaymentRemoteMPI(state, action) {
      return {
        ...state,
        formPaymentRemoteMPI: action.payload,
      };
    },
    setIsUpdateMode(state, action) {
      return {
        ...state,
        isUpdateMode: action.payload,
      };
    },
    setIsUpdateChildrenMode(state, action) {
      return {
        ...state,
        isUpdateChildrenMode: action.payload,
      };
    },
    setIsSpecialEvent(state, action) {
      return {
        ...state,
        isSpecialEvent: action.payload,
      };
    },
    setEventId(state, action) {
      return {
        ...state,
        eventId: action.payload,
      };
    },
    setAmount(state, action) {
      return {
        ...state,
        amount: action.payload,
      };
    },
    setIsUpdateBirthday(state, action) {
      return {
        ...state,
        isUpdateBirthday: action.payload,
      };
    },
  },
});

export const {
  setBookingData,
  setFirstIntention,
  setBookingOptions,
  setBookingConfirm,
  resetState,
  setAlreadyBooking,
  setIsCancelable,
  setNotCancelableReasonKey,
  setEventId,
  setFormPaymentRemoteMPI,
  setIsUpdateMode,
  setIsUpdateChildrenMode,
  setIsSpecialEvent,
  setAmount,
  setIsUpdateBirthday,
} = bookingSlice.actions;

export default bookingSlice.reducer;
