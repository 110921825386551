import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = ({ onClick, children, block, isActive, padding, margin, isDisabled }) => {
  return (
    <Wrapper
      isBlock={block}
      margin={margin}
      onClick={onClick}
    >
      <OuterBorder isDisabled={isDisabled}>
        <OuterBackground>
          <InnerBorder isDisabled={isDisabled}>
            <InnerBackground
              isActive={isActive}
              padding={padding}
              isDisabled={isDisabled}
            >
              {children}
            </InnerBackground>
          </InnerBorder>
        </OuterBackground>
      </OuterBorder>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  width: ${props => props.isBlock ? 'max-content' : 'auto'};
  margin: ${props => props.margin ? props.margin : '1rem'};
`;

const OuterBorder = styled.div`
  display: inline-block;
  background:
    radial-gradient(circle at 0 100%, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brand} 11px),
    radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brand} 11px),
    radial-gradient(circle at 100% 0, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brand} 11px),
    radial-gradient(circle at 0 0, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brand} 11px);

  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  padding: 2px;
  width: 100%;
  cursor: pointer;
  
  &:hover {
    color: ${props => props.isActive ? props.theme.colors.neutral100 : props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brandDarker};
    cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
  }
`;

const OuterBackground = styled.div`
  background:
    radial-gradient(circle at 0 100%, rgba(204,0,0,0) 10px, ${props => props.theme.colors.brandLightest} 0),
    radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 10px, ${props => props.theme.colors.brandLightest} 0),
    radial-gradient(circle at 100% 0, rgba(204,0,0,0) 10px, ${props => props.theme.colors.brandLightest} 0),
    radial-gradient(circle at 0 0, rgba(204,0,0,0) 10px, ${props => props.theme.colors.brandLightest} 0);

  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  padding: 3px;
  width: 100%;
`;

const InnerBorder = styled.div`
  background:
    radial-gradient(circle at 0 100%, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brandLighter} 11px),
    radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brandLighter} 11px),
    radial-gradient(circle at 100% 0, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brandLighter} 11px),
    radial-gradient(circle at 0 0, rgba(204,0,0,0) 10px, ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brandLighter} 11px);

  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  padding: 1px;
  width: 100%;
`;

const InnerBackground = styled.button`
  background:
    radial-gradient(circle at 0 100%, rgba(204,0,0,0) 10px, ${props => props.isActive ? props.theme.colors.brand : props.theme.colors.brandLightest} 5px),
    radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 10px, ${props => props.isActive ? props.theme.colors.brand : props.theme.colors.brandLightest} 5px),
    radial-gradient(circle at 100% 0, rgba(204,0,0,0) 10px, ${props => props.isActive ? props.theme.colors.brand : props.theme.colors.brandLightest} 5px),
    radial-gradient(circle at 0 0, rgba(204,0,0,0) 10px, ${props => props.isActive ? props.theme.colors.brand : props.theme.colors.brandLightest} 5px);

  background-position: bottom left, bottom right, top right, top left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  padding: ${props => props.padding ? props.padding : '0.70rem 2rem'};

  border: none;
  outline: none;
  color: ${props => props.isActive ? props.theme.colors.neutral100 : props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brand};
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover {
    color: ${props => props.isActive ? props.theme.colors.neutral100 : props => props.isDisabled ? props.theme.colors.disabled : props.theme.colors.brandDarker};
    cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
  }
`;

Button.defaultProps = {
  isActive: false,
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
  isActive: PropTypes.bool,
  padding: PropTypes.string,
  margin: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default Button;
