import { connect } from 'react-redux';
import Nav from '../../../Screens/Layout/Nav';
import { tunnelSelector } from '../../../store/slices/tunnel/tunnel.selectors';
import {
  dataBookingConfirm,
  dataIsUpdateBirthday,
  dataIsUpdateChildrenMode,
} from '../../../store/slices/booking/booking.selectors';

const mapStateToProps = state => ({
  tunnelSteps: tunnelSelector(state),
  isUpdateChildrenMode: dataIsUpdateChildrenMode(state),
  bookingConfirm: dataBookingConfirm(state),
  isUpdateBirthday: dataIsUpdateBirthday(state),
});

const NavContainer = connect(
  mapStateToProps,
  null
)(Nav);

export default NavContainer;
