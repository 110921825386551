import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availabilityIsLoading: false,
  bookingIsLoading: false,
  dataIsLoading: false,
  userIsLoading: false,
  userIsLoadingForgotPwd: false,
  specialEventIsLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    setAvailabilityIsLoading: (state, action) => ({
      ...state,
      availabilityIsLoading: action.payload,
    }),
    setBookingIsLoading: (state, action) => ({
      ...state,
      bookingIsLoading: action.payload,
    }),
    setDataIsLoading: (state, action) => ({
      ...state,
      dataIsLoading: action.payload,
    }),
    setUserIsLoading: (state, action) => ({
      ...state,
      userIsLoading: action.payload,
    }),
    setUserIsLoadingForgotPwd: (state, action) => ({
      ...state,
      userIsLoadingForgotPwd: action.payload,
    }),
    setSpecialEventIsLoading: (state, action) => ({
      ...state,
      specialEventIsLoading: action.payload,
    }),
  },
});

export const {
  setAvailabilityIsLoading,
  setBookingIsLoading,
  setDataIsLoading,
  setUserIsLoading,
  setUserIsLoadingForgotPwd,
  setSpecialEventIsLoading,
} = loadingSlice.actions;

export default loadingSlice.reducer;
