import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ModalReact from "react-modal";
import theme from "../../theme";
import {
  useMobileResolutionCheck,
  useTabletResolutionCheck,
} from "../../Hooks/resolution";

const Modal = ({ onClose, show, children, footer, large }) => {
  const isMobile = useMobileResolutionCheck();
  const isTablet = useTabletResolutionCheck();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.colors.modalBackground,
      display: "flex",
    },
    content: {
      position: "initial",
      color: theme.colors.brandDarker,
      width: isMobile ? "95%" : isTablet ? "80%" : large ? "auto" : "50%",
      minWidth: (isMobile || isTablet) && !large ? "auto" : "50%",
      textAlign: "center",
      margin: "auto",
      height: "fit-content",
      padding: "0.3rem",
      maxHeight: "100%",
    },
  };

  return (
    <ModalReact isOpen={show} onRequestClose={onClose} style={modalStyle}>
      <ModalWrapper>
        {children}
        {footer && <ModalActionWrapper>{footer}</ModalActionWrapper>}
      </ModalWrapper>
    </ModalReact>
  );
};

const ModalWrapper = styled.div`
  border: 0.2rem solid ${(props) => props.theme.colors.brand};
  padding: 2rem;
  border-radius: 4px;
`;

const ModalActionWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0.75rem;

  @media ${(props) => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }

  & > div {
    max-width: 70%;
    min-width: 200px;
    display: block;
    margin: 5px 0;
  }
`;

Modal.defaultProps = {
  large: false,
}

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.array,
  show: PropTypes.bool.isRequired,
  footer: PropTypes.object,
  large: PropTypes.bool,
};

export default Modal;
