import { connect } from 'react-redux';
import GuardedRoute from '../../Components/Modules/Router/GuardedRoute';
import {
  dataBookingConfirm,
  dataIsUpdateBirthday,
  dataIsUpdateChildrenMode,
} from '../../store/slices/booking/booking.selectors';
import { logout } from '../../store/slices/user/user.thunks';

const mapStateToProps = state => ({
  isUpdateChildrenMode: dataIsUpdateChildrenMode(state),
  bookingConfirm: dataBookingConfirm(state),
  isUpdateBirthday: dataIsUpdateBirthday(state),
});

const mapDispatchToProps = dispatch => ({
  logout: async () => await dispatch(logout()),
});

const GuardedRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuardedRoute);

export default GuardedRouteContainer;
