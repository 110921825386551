import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavMobile = ({ navItems }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [targetIndex, setTargetIndex] = useState(
    navItems.findIndex((navItem) => navItem.targetPath === location.pathname)
  );
  let currentNavItem = navItems[targetIndex];

  useEffect(() => {
    const newTargetIndex = navItems.findIndex((navItem) => navItem.targetPath === location.pathname);
    setTargetIndex(newTargetIndex);
  }, [location.pathname, navItems]);

  const previousStep = () => {
    if (targetIndex > 0) {
      setTargetIndex(targetIndex - 1);
    }
  };

  const nextStep = () => {
    if (targetIndex < navItems.length - 1) {
      setTargetIndex(targetIndex + 1);
    }
  }

  useEffect(() => {
    history.push(currentNavItem.targetPath);
  }, [currentNavItem, history, targetIndex]);

  return (
    <NavWrapper>
      {targetIndex > 1 && !currentNavItem.isFinalStep ? (
        <ArrowIconWrapper>
          <FontAwesomeIcon
            icon={['fal', 'chevron-left']}
            onClick={previousStep}
          />
        </ArrowIconWrapper>
      ) : <span></span>}

      <NavItem>
        <NavItemIcon>
          <FontAwesomeIcon icon={['fal', currentNavItem.icon]} />
        </NavItemIcon>

        <NavItemLabel>
          {currentNavItem.label}
        </NavItemLabel>

        {!currentNavItem.isFinalStep ? (
          <NavItemStep>
            {t('step')} {currentNavItem.step} {t('on')} {navItems.length}
          </NavItemStep>
        ) : null}
      </NavItem>

      {targetIndex < navItems.length && !currentNavItem.isFinalStep && currentNavItem.validate ? (
        <ArrowIconWrapper>
          <FontAwesomeIcon
            icon={['fal', 'chevron-right']}
            onClick={nextStep}
          />
        </ArrowIconWrapper>
      ) : <span></span>}
    </NavWrapper>
  );
};

const NavWrapper = styled.div`
  width: 100%;
  height: 5rem;
  background: ${props => props.theme.colors.brand};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutral100};
`;

const NavItemIcon = styled.div`
  font-size: 1.4rem;
`;

const NavItemLabel = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const NavItemStep = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.colors.pannelBackgroundDarker};
`;

const ArrowIconWrapper = styled.div`
  color: ${props => props.theme.colors.fontTitle};
`;

NavMobile.propTypes = {
  navItems: PropTypes.array.isRequired
};

export default NavMobile;
