import React, {useEffect, useState} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import store, { Persistor } from './store';
import AppRouter from './Screens/AppRouter';
import styled, { ThemeProvider } from 'styled-components';
import theme from './theme';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import './App.css';
import ReactModal from 'react-modal';
import { refreshStore } from './helpers/timeout';
import 'dayjs/locale/fr';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {toast} from "react-toastify";
import i18n from "./i18n";
import Title from "./Components/UI/Title";
import Modal from "./Components/UI/Modal";
import Button from "./Components/UI/Button";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {http} from "./helpers/http";


library.add(fal, fad)
ReactModal.setAppElement("body");

setInterval(() => {
  refreshStore();
}, 60000);

const queryClient = new QueryClient();

function App() {

  const { t } = useTranslation();
  const [exceptionalMessage, setExceptionalMessage] = useState(false)

  useEffect(() => {
    getExceptionalMessage();
  }, []);

  const getExceptionalMessage = async () => {
    try {
      const data = await http.get(`/context/settings`);
      data && data.data && data.data.exceptional_message && setExceptionalMessage(data.data.exceptional_message);
    } catch ($e) {}
  }

  const modalActions = (
    <Button
      block={false}
      onClick={() => setExceptionalMessage(null)}
    >
      {t('btn_send')}
    </Button>
  );

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={Persistor}
      >
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AppRouter />
            <Modal
              show={!!exceptionalMessage}
              footer={modalActions}
            >
              <Title
                icon="exclamation-triangle"
                label=""
              />
              <ExceptionalMessageText>
                {exceptionalMessage}
              </ExceptionalMessageText>
            </Modal>
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

const ExceptionalMessageText = styled.p`
  color: #FF4444FF;
`;

export default App;
