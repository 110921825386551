import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';

import dataSlice from './slices/data/data.slice';
import tunnelSlice from './slices/tunnel/tunnel.slice';
import bookingSlice from './slices/booking/booking.slice';
import userSlice from './slices/user/user.slice';
import availabilitySlice from './slices/availability/availability.slice';
import loadingSlice from './slices/loading/loading.slice';

const persistConfig = {
  key: 'lgb',
  whitelist: ['tunnelSlice', 'dataSlice', 'bookingSlice', 'userSlice', 'availabilitySlice'],
  storage,
  stateReconciler: autoMergeLevel2,
};

const reducer = combineReducers({ dataSlice, tunnelSlice, bookingSlice, userSlice, availabilitySlice, loadingSlice });
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const Persistor = persistStore(store);
export default store;
