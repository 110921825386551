import { useState } from 'react';
import theme from '../theme';

const useMobileResolutionCheck = () => {
  /**
   * We don't need that state
   * We simply use this to store current width and height of the window, thus artificially triggering
   * the hook (and subsequently the Nav.js component) re-render.
   * The thing is, matchMedia triggers once when the page loads, it cannot recompute after the window
   * has been resized. We use matchMedia to determine if we show the user the NavMobile.js or NavDesktop.js.
   * As we update a useless state in this hook, we force a re-render of the hook itself, that'll eventually
   * spread to any component that use it.
   */
  // eslint-disable-next-line
  const [heightOutput, setHeightOutput] = useState(document.querySelector('#height'));
  // eslint-disable-next-line
  const [widthOutput, setWidthOutput] = useState(document.querySelector('#width'));

  window.onresize = () => {
    setHeightOutput(window.innerHeight);
    setWidthOutput(window.innerWidth);
  };

  return window.matchMedia(theme.breakpoints.mobile).matches
}

const useTabletResolutionCheck = () => {
  /**
   * See the comment above
   */
  // eslint-disable-next-line
  const [heightOutput, setHeightOutput] = useState(document.querySelector('#height'));
  // eslint-disable-next-line
  const [widthOutput, setWidthOutput] = useState(document.querySelector('#width'));

  window.onresize = () => {
    setHeightOutput(window.innerHeight);
    setWidthOutput(window.innerWidth);
  };

  return window.matchMedia(theme.breakpoints.tablet).matches
}

const useDesktopResolutionCheck = () => {
  /**
   * See the comment above
   */
  // eslint-disable-next-line
  const [heightOutput, setHeightOutput] = useState(document.querySelector('#height'));
  // eslint-disable-next-line
  const [widthOutput, setWidthOutput] = useState(document.querySelector('#width'));

  window.onresize = () => {
    setHeightOutput(window.innerHeight);
    setWidthOutput(window.innerWidth);
  };

  return window.matchMedia(theme.breakpoints.desktop).matches
}

export {
  useMobileResolutionCheck,
  useTabletResolutionCheck,
  useDesktopResolutionCheck
}
