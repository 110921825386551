export default {
  colors: {
    brandDarkest: '#9b7900',
    brandDarker: '#9c7a2f',
    brand: '#C89D3D',
    brandLighter: '#EED8AD',
    brandLightest: '#FFFCF4',
    background: '#0d0500',
    pannelBackground: '#FAF8F2',
    pannelBackgroundDarker: '#F4EDD8',
    font: '#A59F8A',
    fontGray: '#ABABA9',
    strongGray: '#858585',
    fontTitle: '#624712',
    lightGray: '#D1D1D1',
    disabled: '#C8C8C8',
    nightDarker: '#1742bc',
    nightDarkest: '#002dbf',
    night: '#4F6CBE',
    neutral100: '#ffffff',
    errorLightest: '#f8d7da',
    errorLighter: '#f5c6cb',
    error: '#DC3545',
    modalBackground: 'rgba(0, 0, 0, 0.8)',
    validLightest: '#d4edda',
    validLighter: '#c3e6cb',
    valid: '#52df76',
    validDarker: '#155724',
  },
  breakpoints: {
    mobile: `(max-width: 767px)`,
    desktop: `(min-width: 768px)`
  }
}
