import store from '../store';
import { dataTimeout } from '../store/slices/booking/booking.selectors';

export const refreshStore = () => {

  const now = (new Date()).getTime();
  const date = dataTimeout(store.getState());

  if (now > (date+3600000)) {
    window.localStorage.clear();
    window.location.href = `${window.location.origin}/contact`;
  }
};
