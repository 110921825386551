import React, { useCallback, useEffect, useState } from 'react';
import { useMobileResolutionCheck, useTabletResolutionCheck } from '../../Hooks/resolution';
import { useLocation } from 'react-router-dom';
import NavMobile from './NavMobile';
import NavDesktop from './NavDesktop';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import PropTypes from 'prop-types';

const Nav = ({
  tunnelSteps,
  isUpdateChildrenMode,
  bookingConfirm,
  isUpdateBirthday,
}) => {

  const location = useLocation();
  const initiateNavItems = useCallback(() => [
    {
      id: Math.random(),
      label: 'contact',
      step: 1,
      icon: 'mailbox',
      targetPath: '/contact',
      isClickable: false,
      validate: tunnelSteps.contact,
    },
    {
      id: Math.random(),
      label: 'date & participants',
      step: 2,
      icon: 'calendar-day',
      targetPath: '/date-and-guests',
      isClickable: tunnelSteps.dateAndGuest,
      validate: tunnelSteps.dateAndGuest,
    },
    {
      id: Math.random(),
      label: 'heure & salle',
      step: 3,
      icon: 'clock',
      targetPath: '/time-and-room',
      isClickable: tunnelSteps.dateAndGuest,
      validate: tunnelSteps.timeAndRoom,
    },
    {
      id: Math.random(),
      label: 'Options',
      step: 4,
      icon: 'sliders-h-square',
      targetPath: '/booking-options',
      isClickable: tunnelSteps.timeAndRoom,
      validate: tunnelSteps.bookingOption,
    },
    {
      id: Math.random(),
      label: 'informations',
      step: 5,
      icon: 'user',
      targetPath: '/user-information',
      isClickable: tunnelSteps.bookingOption,
      validate: tunnelSteps.userInformation,
    },
    {
      id: Math.random(),
      label: 'Nous vous attendons!',
      isFinalStep: true,
      step: 6,
      icon: 'hat-chef',
      targetPath: '/booking-complete',
      isClickable: tunnelSteps.userInformation,
      validate: tunnelSteps.bookingComplete,
    },
  ], [tunnelSteps]);
  const [navItems, setNavItems] = useState(initiateNavItems());
  const { trackEvent } = useMatomo();

  useEffect(() => {
    setNavItems(initiateNavItems());
  }, [tunnelSteps, initiateNavItems]);

  const pathname = `${window.location.pathname.substr(1)}${window.location.search}`;

  if (pathname !== '') {
    trackEvent({
      category: 'Accès à l\'url',
      action: pathname,
    });
  }

  const isMobile = useMobileResolutionCheck();
  const isTablet = useTabletResolutionCheck();

  if (navItems.every((navItem) => navItem.targetPath !== location.pathname)) {
    return null;
  }

  // Sometimes nav still appears on mobile.
  if (isMobile || isUpdateChildrenMode || isUpdateBirthday) {
    return <></>;
  }

  if (isTablet) {
    return (
      <NavMobile navItems={navItems}/>
    );
  }

  return (
    <NavDesktop navItems={navItems} bookingConfirm={bookingConfirm}/>
  );
};

Nav.propTypes = {
  bookingConfirm: PropTypes.object.isRequired,
  isUpdateChildrenMode: PropTypes.bool,
  isUpdateBirthday: PropTypes.bool,
};

export default Nav;
